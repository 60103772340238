// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PORTFOLIO_CHARTS_DATA = "GET_PORTFOLIO_CHARTS_DATA";
export const GET_MARKET_CHARTS_DATA = "GET_MARKET_CHARTS_DATA";
export const FETCH_DEVICE_DATA = 'FETCH_DEVICE_DATA';
export const FETCH_DEVICE_DATA_SUCCESS = 'FETCH_DEVICE_DATA_SUCCESS';
export const FETCH_DEVICE_DATA_FAILURE = 'FETCH_DEVICE_DATA_FAILURE';

export const FETCH_ACCOUNT_ID = 'FETCH_ACCOUNT_ID '

export const FETCH_ACCOUNT_ID_SUCCESS='FETCH_ACCOUNT_ID_SUCCESS'

export const FETCH_ACCOUNT_ID_FAILURE='FETCH_ACCOUNT_ID_FAILURE'