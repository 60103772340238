module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {

    // API_URL: "http://35.160.4.251:5000/",
    API_URL: "https://api.secondsunlabs.com/"
  }
};